import API from 'o365.modules.data.api.ts';
import type DataObject from 'o365.modules.DataObject.ts';
import FilterObject from 'o365.modules.FilterObject.ts';
import {getOrCreateDataObject} from "o365.vue.ts";
import {convertType} from 'o365.modules.utils.fields.ts';
import {getReportFieldsDO} from 'o365.modules.filters.store.ts';
import FilterGroup from 'o365.modules.FilterGroup.ts';
import FilterTemplateItem from 'o365.modules.FilterTemplateItem.ts';


const configs = new Map();

export class FilterTemplates{
    private _filterObject:FilterObject;
   // private _dsFilterTemplates:DataObject;
    primKey:string;
    id:string;
    reportID:string;
    maxRecords:number = 1000;
    previewLoading:boolean = false;
    activePreviewFilter:string|null = null;
    loading:boolean = false;
    isPublic:boolean = false;
    isMine:boolean = false;
    error:string = '';
    name:string;
    viewName:string;
    latestBlobUrl:string;
    fieldsDS:DataObject;
    reportDS:DataObject;
    filterGroup:any;
    newItem:FilterTemplateItem;




    saving:boolean = false;
    columns:Array<{column:string,
                    name:string,
                    type:string,
                    caption:string,
                    maxLength?:number
                }> = [];

    get isComplete(){
      
        if(!this.filterGroup) return true;

        return this.filterGroup.isComplete;
     
    }

    get activePreviewFilterHasChanges(){
        if(this.activePreviewFilter == null) return false;
        return this.filterGroup.filterString !== this.activePreviewFilter;
    }
   

    get filterObject() {
        if (!this._filterObject) {
            this._filterObject = new FilterObject({
                dataObject: this,
                columns: this.columns
            })
            this._filterObject.apply = this.apply;
            this._filterObject.updateFilterString = () =>{
             
                if(this.currentFilterObjectItem && this.currentFilterObjectItem.selectedValue && this.newItem.value !== this.currentFilterObjectItem.selectedValue)
                this.newItem.value = this.currentFilterObjectItem.selectedValue;
              
            }
          //  this.filterObject.dataObject = this.reportDS;
        }

        return this._filterObject;
    }

    get currentFilterObjectItem(){
        if(this.newItem.inputEditor !== "Fixed Value") return null;


       
        return this.filterObject.getItem(this.newItem.column);
    }

 

    

    constructor(pReportID:string){
        this.reportID = pReportID;

        this.fieldsDS = getReportFieldsDO(pReportID);
    }

    async applySavedFilter(pFilter:any){
        await this.updateFilterGroup(pFilter);
    }


    async updateFilterGroup(pData:any){
        this.loading = true;
        if(!this.columns && pData.FilterFields){
             this.setInitColumns(pData.FilterFields.split(","));
        }
        if(!this.columns){
            if(pData.JsonDataColumns){
                try{
                    this.setInitColumns(JSON.parse(pData.JsonDataColumns));
                }catch{

                }
                
            }
        }
        
   
        if(!this.filterGroup) this.filterGroup = new FilterGroup(this.columns);
        this.filterGroup.columns = this.columns;
        this.newItem = new Proxy(new FilterTemplateItem({}), {
                        set: (obj, prop, value) => {
                            Reflect.set(obj, prop, value);
                          //  if (prop == "value") this.updateNewItem();
                           // if (prop == "inputEditor") this.updateNewItem();
                            if (prop == "operator") this.updateNewItem();
                           // if (prop == "column") this.updateNewItem();
                            //if (prop == "name") this.updateNewItem();
                            return true;
                        }
                    });
                    //console.log( this.newItem);
                   // this.newItem = new FilterTemplateItem({},this.columns);
        if(pData.PrimKey) this.primKey = pData.PrimKey;
        if(pData.FilterName){
            this.name = pData.FilterName;
            this.filterGroup.filterGroupName = pData.FilterName;
        }
        this.isPublic = pData.IsPublic??false;
        this.isMine = pData.Mine??false;
        this.id = pData.ID;
        if(pData.FilterCriteria)
        await this.filterGroup.updateFilterGroupFromObject(pData);
        this.loading = false;
        if(this.isComplete && this.filterGroup.template){
           // this.getReport("view","pdf");
        }
        
    }

    updateNewItem(){
        if(!this.currentFilterObjectItem) return;
        if(this.currentFilterObjectItem.operator !== this.newItem.operator){
            this.currentFilterObjectItem.operator=this.newItem.operator;
            
        }
        if(this.newItem.operator.indexOf("inlist") > -1){
                this.currentFilterObjectItem.showDistinct = true;
            }else{
                this.currentFilterObjectItem.showDistinct = false;
            }
            
        
    }

    async updateTemplate(){
        return await this.filterGroup.updateTemplate();
    }

    async save(){
        if(!this.filterGroup.template) return;
        this.saving = true;
        
        /*const vRes = await this.updateTemplate();
        if(!vRes){
            return;
        }*/

        const vData = {
            FilterCriteria: this.filterGroup.template
        }

        if(this.primKey){
            vData["PrimKey"] = this.primKey;
            vData["FilterTemplate"] = JSON.stringify(this.filterGroup.toJSON);
            this.filterObject.filtersListDO.storage.updateItemByPrimKey(this.primKey, vData);
            this.filterObject.filtersListDO.save();

        } else {
            var vItem =   this.filterObject.filtersListDO.createNew();
            vItem.FilterName = this.name; 
            vItem.FilterCriteria = this.filterGroup.filterTemplate;
            vItem.FilterTemplate = JSON.stringify(this.filterGroup.toJSON);
            vItem.DBObjectID = this.viewName;

            this.filterObject.filtersListDO.save().then((pRow)=>{
                if (pRow.length > 0 && pRow[0].length > 0) {
                    this.applySavedFilter(pRow[0][0]);
                }
           })
        }

        // const vData = {
        //     viewName:"stbv_Database_Filters",
        //     fields:[{name:"FilterCriteria"},{name:"PrimKey"}],
        //     operation:this.primKey?'update':'create',
        //     values:{
        //         FilterCriteria:this.filterGroup.template,
        //     }
        // }
      
        // if(this.primKey){
        //     vData.values["PrimKey"] = this.primKey;

        // }else{
        //     vData.values["DBObjectID"] = this.viewName;
        //     vData.values["IsPublic"] = this.isPublic;
           
        //     vData.values["FilterName"] = this.name;
        // }
        // vData.values["FilterTemplate"] = JSON.stringify(this.filterGroup.toJSON);

        // const vResults = await API.requestPost("/api/data/stbv_Database_Filters",JSON.stringify(vData));
        // if(!this.primKey){
        //     this.primKey = vResults[0]["PrimKey"];
        // }
        // if(this.filterObject.filtersListDO && this.primKey)
        // this.filterObject.filtersListDO.recordSource.refreshRowByPrimKey(this.primKey)

        this.filterGroup.prevFilterTemplate = this.filterGroup.template;

        this.saving = false;

        //return vResults;
    }

    get filtersListDO(){
        return this.filterObject.filtersListDO;
    }

    cancel(){
        this.filterGroup.template =  this.filterGroup.prevFilterTemplate;
        this.filterGroup.error = "";
    }

    add(){

        this.filterGroup.add(this.newItem);
      
    }

    apply =()=>{
        //implementing
        console.log("applyt");
    }

    async getReport(pType:string,pExtension:string,pForceCT:boolean=false){

        this.filterGroup.updateFilterString();

        this.activePreviewFilter = this.filterGroup.filterString;
        if(pType === "view"){
            this.previewLoading = true;
        }else{
            this.loading = true;
        }
        

        return API.requestFileCustomHeaders(`${pForceCT?"":"/nt"}/file/${pType}/reporting/${this.reportID}/${this.reportID}.${pExtension}${pForceCT?`?MaxRecords=${this.maxRecords}`:""}`,JSON.stringify({
            maxRecords:this.maxRecords,
            [pForceCT?'filterString':'filter']:this.filterGroup.filterString
        }), { "Content-Type": "application/json; charset=utf-8" }).then(blob=>{
            if(!blob){
                alert("Something not right");
                this.loading = false;
                this.previewLoading = false;
                return null;
            }
           
            let url = window.URL.createObjectURL(blob);
    
            if(pType !== 'view'){
                var a = document.createElement("a");
                a.href = url;
                a.download = `${this.reportID}.${pExtension}`;
                a.click();
            }else{
                
                this.latestBlobUrl = url;
            }
            this.loading = false;
            this.previewLoading = false;
            return url;
           /* window.open(url, '_blank');
            URL.revokeObjectURL(url);*/
           
             
        }).catch(ex=>{
            alert(ex);
        });


    }

    async setInitColumns(pColumns:Array<any>){
        if(!this.columns){
            this.columns = [];
            pColumns.forEach(item=>{
                this.columns.push({
                    column:item.trim(),
                    name:item.trim(),
                    caption:item.trim(),
                    type:"string",
               //     maxLength:-1
                })
            })
        }
    }

    async updateColumns(){
       
        await this._updateColumns();
        if(this._filterObject){
            this._filterObject.updateColumns(this.columns);
        }
        
    }
    private async _updateColumns(){
        await this.fieldsDS.load();
        this.columns = [];
        this.fieldsDS.data.forEach(col=>{
            this.columns.push({
                column:col['FieldName'],
                name:col['FieldName'],
                type:convertType(col['DataType']),
                caption:col['DisplayName'],
                maxLength:col['ColumnLength']
            })
        })


        this.reportDS = getOrCreateDataObject({
            id:'ds_o365_'+this.reportID,
            maxRecords:1,
            fields:this.columns,
            viewName:this.viewName
        })

      //  this.filterGroup.columns = this.columns;
  
    }
}


